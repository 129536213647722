import { render, staticRenderFns } from "./Setting.vue?vue&type=template&id=1277acc8&scoped=true&"
import script from "./Setting.vue?vue&type=script&lang=js&"
export * from "./Setting.vue?vue&type=script&lang=js&"
import style0 from "./Setting.vue?vue&type=style&index=0&id=1277acc8&scoped=true&lang=css&"
import style1 from "./Setting.vue?vue&type=style&index=1&lang=css&"
import style2 from "spinkit/scss/spinkit.scss?vue&type=style&index=2&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1277acc8",
  null
  
)

export default component.exports