<template>
  <b-row>
    <b-col sm="12">
      <b-card>
        <div class="loading" v-if="isLoading == true">
            <div class="sk-three-bounce">
                <div class="sk-child sk-bounce1"></div>
                <div class="sk-child sk-bounce2"></div>
                <div class="sk-child sk-bounce3"></div>
            </div>
        </div>
        <div slot="header">
          <strong>Usership Settings</strong>
        </div>
        <b-form @submit.prevent="setting">
          <b-row class="form-group">
            <b-col md="3">
              <strong>Current Date Periode</strong>
            </b-col>
            <b-col sm="12" md="3">
              <strong>
                {{ this.currentStart }} ~ {{ this.currentEnd }}
              </strong>
            </b-col>
          </b-row>
          <b-row class="form-group">
            <b-col md="3">
              <span>Date Range Period</span>
            </b-col>
            <b-col sm="12" md="3">
              <b-input-group
              >
                <b-input-group-prepend is-text>
                  <i class="fa fa-calendar"></i>
                </b-input-group-prepend>
                <transaction-picker id="daterange" :start="startDate" :end="endDate" @picker="changeDate"></transaction-picker>
              </b-input-group>
            </b-col>
          </b-row>
          <b-row class="form-group">
            <b-col md="3">
              <span>Active User</span>
            </b-col>
            <b-col md="3">
              <b-form-input type="text" v-model="form.lower" disabled></b-form-input>
            </b-col>
            <b-col md="3">
              <b-form-input type="text" v-model="form.middle" disabled></b-form-input>
            </b-col>
            <b-col md="3">
              <b-form-input type="text" v-model="form.higher" disabled></b-form-input>
            </b-col>
          </b-row>
          <b-row class="form-group">
            <b-col md="3">
              <span>Active User Buy Transaction</span>
            </b-col>
            <b-col md="3">
              <b-form-input type="text" v-model="form.lowerBuy" disabled></b-form-input>
            </b-col>
            <b-col md="3">
              <b-form-input type="text" v-model="form.middleBuy" disabled></b-form-input>
            </b-col>
            <b-col md="3">
              <b-form-input type="text" v-model="form.higherBuy" disabled></b-form-input>
            </b-col>
          </b-row>
          <b-row class="form-group">
            <b-col md="3">
              <span>Active User Sell Transaction</span>
            </b-col>
            <b-col md="3">
              <b-form-input type="text" v-model="form.lowerSell" disabled></b-form-input>
            </b-col>
            <b-col md="3">
              <b-form-input type="text" v-model="form.middleSell" disabled></b-form-input>
            </b-col>
            <b-col md="3">
              <b-form-input type="text" v-model="form.higherSell" disabled></b-form-input>
            </b-col>
          </b-row>
          <b-row class="form-group">
            <b-col md="3">
              <span>Active User Gram Purchase</span>
            </b-col>
            <b-col md="3">
              <b-form-input type="text" v-model="form.lowerGram" disabled></b-form-input>
            </b-col>
            <b-col md="3">
              <b-form-input type="text" v-model="form.middleGram" disabled></b-form-input>
            </b-col>
            <b-col md="3">
              <b-form-input type="text" v-model="form.higherGram" disabled></b-form-input>
            </b-col>
          </b-row>
          <b-row class="form-group">
            <b-col md="3">
              <span>Active User Money Spent</span>
            </b-col>
            <b-col md="3">
              <b-form-input type="text" v-model="form.lowerPrice" disabled></b-form-input>
            </b-col>
            <b-col md="3">
              <b-form-input type="text" v-model="form.middlePrice" disabled></b-form-input>
            </b-col>
            <b-col md="3">
              <b-form-input type="text" v-model="form.higherPrice" disabled></b-form-input>
            </b-col>
          </b-row>
          <b-row class="form-group">
            <b-col md="3">
              <span>Silent User</span>
            </b-col>
            <b-col md="3">
              <b-form-input type="text"
                v-model.lazy.trim="$v.form.lowerWeek.$model"
                :state="chkState('lowerWeek')"
                aria-describedby="lowerFeedback"
                autocomplete='given-name'
                autofocus
              ></b-form-input>
              <b-form-invalid-feedback id="lowerFeedback" >
                <span v-if="!$v.form.lowerWeek.required">- Lower Week tidak boleh kosong!</span>
              </b-form-invalid-feedback>
            </b-col>
            <b-col md="3">
              <b-form-input type="text"
                v-model.lazy.trim="$v.form.middleWeek.$model"
                :state="chkState('middleWeek')"
                aria-describedby="middleFeedback"
                autocomplete='given-name'
                autofocus
              ></b-form-input>
              <b-form-invalid-feedback id="middleFeedback" >
                <span v-if="!$v.form.middleWeek.required">- Middle Week tidak boleh kosong!</span>
              </b-form-invalid-feedback>
            </b-col>
            <b-col md="3">
              <b-form-input type="text"
                v-model.lazy.trim="$v.form.higherWeek.$model"
                :state="chkState('higherWeek')"
                aria-describedby="HigherFeedback"
                autocomplete='given-name'
                autofocus
              ></b-form-input>
              <b-form-invalid-feedback id="higherFeedback" >
                <span v-if="!$v.form.higherWeek.required">- Higher Week tidak boleh kosong!</span>
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
          <b-row class="form-group">
            <b-col md="3">
              <span>Dead User</span>
            </b-col>
            <b-col sm="12" md="3">
              <b-form-input type="text"
                v-model.lazy.trim="$v.form.minDead.$model"
                :state="chkState('minDead')"
                aria-describedby="minDeadFeedback"
                autocomplete='given-name'
                autofocus
              ></b-form-input>
              <b-form-invalid-feedback id="minDeadFeedback" >
                <span v-if="!$v.form.minDead.required">- Minimum Dead Week tidak boleh kosong!</span>
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
          <div slot="footer">
            <b-button type="submit" size="sm" variant="primary"><i class="fa fa-dot-circle-o"></i> Submit</b-button>
          </div>
        </b-form>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { validationMixin } from "vuelidate"
import moment from 'moment';
import { required } from "vuelidate/lib/validators"
export default {
  name: 'setting-usership',
  data () {
    return {
      startDate: '',
      endDate: '',
      currentStart: '',
      currentEnd: '',
      form: {
        lower: '',
        middle: '',
        higher: '',
        lowerWeek: '',
        middleWeek: '',
        higherWeek: '',
        lowerPrice: '',
        middlePrice: '',
        higherPrice: '',
        lowerGram: '',
        middleGram: '',
        higherGram: '',
        lowerBuy: '',
        middleBuy: '',
        higherBuy: '',
        lowerSell: '',
        middleSell: '',
        higherSell: '',
        minDead: ''
      },
      isLoading: false
    }
  },
  created () {
    this.$http.get(`bma/setting`)
      .then((response) => {
        this.currentStart = response.data.data.start;
        this.currentEnd = response.data.data.end;
        this.form.lower = response.data.data.lower;
        this.form.middle = response.data.data.middle;
        this.form.higher = response.data.data.higher;
        this.form.lowerWeek = response.data.data.lower_week;
        this.form.middleWeek = response.data.data.middle_week;
        this.form.higherWeek = response.data.data.higher_week;
        this.form.lowerPrice = response.data.data.lower_price;
        this.form.middlePrice = response.data.data.middle_price;
        this.form.higherPrice = response.data.data.higher_price;
        this.form.lowerGram = response.data.data.lower_gram;
        this.form.middleGram = response.data.data.middle_gram;
        this.form.higherGram = response.data.data.higher_gram;
        this.form.lowerBuy = response.data.data.lower_buy;
        this.form.middleBuy = response.data.data.middle_buy;
        this.form.higherBuy = response.data.data.higher_buy;
        this.form.lowerSell = response.data.data.lower_sell;
        this.form.middleSell = response.data.data.middle_sell;
        this.form.higherSell = response.data.data.higher_sell;
        this.form.minDead = response.data.data.dead_week;
      })
  },
  computed: {
    formStr() { return JSON.stringify(this.form, null, 4) },
    isValid() { return !this.$v.form.$anyError },
    isDirty() { return this.$v.form.$anyDirty },
    invCheck() { return 'You must accept before submitting' },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      lowerWeek: {
        required,
      },
      middleWeek: {
        required
      },
      higherWeek: {
        required
      },
      minDead: {
        required
      }
    }
  },
  methods: {
    changeDate (value) {
      this.startDate = value.startDate;
      this.endDate = value.endDate;
    },
    setting() {
      if (this.startDate == '' && this.endDate == '') {
        this.startDate = moment('2018-11-21').format('YYYY-MM-DD')
        this.endDate = moment().format('YYYY-MM-DD')
      }

      this.validate()
      this.isLoading = true
      this.$http.post(`bma/setting`, {
        start: this.startDate,
        end: this.endDate,
        lower_week: this.form.lowerWeek,
        middle_week: this.form.middleWeek,
        higher_week: this.form.higherWeek,
        dead_week: this.form.minDead
      }).then((result) => {
        this.isLoading = false
        this.$swal.fire(
            'Success!',
            result.data.data,
            'success'
          ).then(() =>{
            location.reload()
          })
      }).catch((error) => {
        if (error.response) {
          this.isLoading = false
          if (error.response.data.meta.code == 422) {
            this.$swal.fire(
                'Failed!',
                error.response.data.meta.message,
                'error'
              )
          }
        }status
      })
    },
    chkState(val) {
      const field = this.$v.form[val]
      return !field.$dirty || !field.$invalid
    },
    findFirstError(component = this) {
      if (component.state === false) {
        if (component.$refs.input) {
          component.$refs.input.focus()
          return true
        }
        if (component.$refs.check) {
          component.$refs.check.focus()
          return true
        }
      }
      let focused = false
      component.$children.some((child) => {
        focused = this.findFirstError(child)
        return focused
      })

      return focused
    },
    validate() {
      this.$v.$touch()
      this.$nextTick(() => this.findFirstError())
      return this.isValid
    }
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

<style>
  /* Absolute Center Spinner */
  .loading {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: visible;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  /* Transparent Overlay */
  .loading:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
  }
</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
